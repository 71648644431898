import decode from "jwt-decode";
import cookie from "js-cookie";
import { toMils } from "libs/time-utc";
import { ITokenPayload } from "./interfaces";
export const KEY = "DAT_AUTH";
export const SUPERSET_KEY = "SUPERSET_AUTH";
export const FIREBASE_DB_NAME = "firebaseLocalStorageDb";

export function removeAuthTokens() {
  indexedDB.deleteDatabase(FIREBASE_DB_NAME);
  cookie.remove(KEY);
  cookie.remove(SUPERSET_KEY);
}

export async function getToken() {
  return cookie.get(KEY) ?? "";
}

// returns token expiration time in mils
export function getTokenExpirationTime() {
  return getToken().then((el) => {
    return el ? toMils(parsePayload(el)!.exp) : null;
  });
}

export function parsePayload(token: string): ITokenPayload | null {
  if (!token) {
    return null;
  }
  return decode(token);
}
